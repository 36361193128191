<template>
  <div>
    <header v-if="!isLoading">
      <nav class="bg-white border-gray-200 py-2.5 border-b dark:bg-gray-800">
        <div
          class="flex flex-wrap justify-between items-center w-full px-4 pr-8"
        >
          <h1
            class="flex py-2 rounded-md text-3xl text-gray-900 w-[240px] text-center font-semibold tracking-tight"
            :class="user ? 'items-start' : 'intems-center mx-auto'"
          >
            🚀 OrdemioAi
          </h1>

          <div class="flex items-center lg:order-2" v-if="user && !isLoading">
            <div href="#">Hi, {{ user.name }}</div>
            <a
              @click.prevent="logout()"
              href="#"
              class="ml-4 text-gray-800 bg-gray-100 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 focus:outline-none"
              >Log out</a
            >
          </div>
        </div>
      </nav>
    </header>
    <div class="flex flex-row">
      <div class="basis-2/12" v-if="user">
        <side-bar :user="user"></side-bar>
      </div>
      <div v-if="user" class="basis-10/12 px-5 ml-2">
        <router-view></router-view>
      </div>
      <div v-if="!user && !isLoading" class="pt-10 items-center w-full">
        <login-form></login-form>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from "./components/auth/LoginForm.vue";
import SideBar from "./components/SideBar.vue";
import api from "./api";
export default {
  components: { SideBar, LoginForm },
  name: "App",
  data: function () {
    return {
      user: null,
      isLoading: true,
    };
  },
  beforeMount() {
    this.getUser();
  },
  methods: {
    getUser() {
      let that = this;
      if (!localStorage.getItem("access_token")) {
        that.isLoading = false;
        return;
      }
      api.get("/api/account/get").then((r) => {
        that.isLoading = false;
        if (r.data) {
          that.user = r.data;
        }
      });
    },
    logout() {
      localStorage.removeItem("access_token");
      window.location.href = "/";
    },
  },
};
</script>
