<template>
  <div class="pt-5">
    <bread-crumb :items="bitems"></bread-crumb>
    <h1 class="text-3xl text-gray-800 font-semibold mb-5">
      <svg
        class="w-9 h-9 text-gray-800 inline-block"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M21 13v-2a1 1 0 0 0-1-1h-.757l-.707-1.707.535-.536a1 1 0 0 0 0-1.414l-1.414-1.414a1 1 0 0 0-1.414 0l-.536.535L14 4.757V4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v.757l-1.707.707-.536-.535a1 1 0 0 0-1.414 0L4.929 6.343a1 1 0 0 0 0 1.414l.536.536L4.757 10H4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h.757l.707 1.707-.535.536a1 1 0 0 0 0 1.414l1.414 1.414a1 1 0 0 0 1.414 0l.536-.535 1.707.707V20a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-.757l1.707-.708.536.536a1 1 0 0 0 1.414 0l1.414-1.414a1 1 0 0 0 0-1.414l-.535-.536.707-1.707H20a1 1 0 0 0 1-1Z"
        />
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
        />
      </svg>
      Account Settings
    </h1>
    <div
      v-if="user"
      class="shadow-md p-5 rounded-lg border-gray-100 border relative mb-8"
    >
      <h2 class="text-lg font-medium text-gray-900 mb-2">Contact details</h2>
      <address
        class="relative bg-gray-50 p-4 rounded-lg border border-gray-200 not-italic grid grid-cols-2 mb-6"
      >
        <div class="space-y-2 text-gray-500 leading-loose hidden sm:block">
          Name <br />
          Email <br />
          Date created
        </div>
        <div
          id="contact-details"
          class="space-y-2 text-gray-900 dark:text-white font-medium leading-loose"
        >
          {{ user.name }} <br />
          {{ user.email }} <br />
          {{ user.created_at }}
        </div>
      </address>
      <label
        for="api-key"
        class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >Your API key</label
      >
      <input
        v-model="user.api_key"
        disabled
        readonly
        id="api-key"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
      />
      <p
        id="helper-text-explanation"
        class="mt-2 text-sm text-gray-500 dark:text-gray-400"
      >
        Use this API key to Bearer Authorization.
      </p>
    </div>
    <div
      v-if="user"
      class="shadow-md p-5 rounded-lg border-gray-100 border relative mb-8"
    >
      <label
        for="api-key"
        class="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
        >OpenAI API key</label
      >
      <div
        id="alert-3"
        class="flex items-center p-4 mb-4 text-green-800 rounded-lg bg-green-50"
        role="alert"
        v-if="saved"
      >
        <span class="font-medium text-sm">Success!</span>
        <div class="ms-1 text-sm">Change is saved.</div>
        <button
          type="button"
          @click="saved = false"
          class="ms-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex items-center justify-center h-8 w-8"
        >
          <span class="sr-only">Close</span>
          <svg
            class="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
        </button>
      </div>
      <input
        v-model="key.key"
        id="api-key"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
      />
      <p
        id="helper-text-explanation"
        class="mt-2 text-sm text-gray-500 dark:text-gray-400"
      >
        Enter your OpenAI Api-key.
      </p>
      <div class="mt-3">
        <button
          type="button"
          v-on:click="submitForm()"
          class="text-white bg-lime-500 hover:bg-lime-600 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
        >
          Save key
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../../api";
import BreadCrumb from "../BreadCrumb.vue";
export default {
  name: "AccountSettings",
  data: function () {
    return {
      bitems: [
        {
          text: "Account Settings",
          link: "/settings",
        },
      ],
      user: null,
      key: { key: null },
      saved: false,
    };
  },
  components: {
    BreadCrumb,
  },
  beforeMount() {
    this.getUser();
  },
  methods: {
    getUser() {
      let that = this;
      api.get("/api/account/get").then((r) => {
        if (r.data) {
          that.user = r.data.user;
          that.key = r.data.key;
        }
      });
    },
    submitForm() {
      let self = this;
      api.post("/api/account/set-key", { key: this.key }).then((r) => {
        if (r.data) {
          self.saved = true;
          self.getUser();
        }
      });
    },
  },
};
</script>
