<template>
  <div class="chat-message flex p-4 text-gray-800 border-t">
    <svg
      class="w-6 h-6 text-gray-800 dark:text-white inline-block mr-3 basis-9"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="currentColor"
        stroke-width="2"
        d="M7 17v1c0 .6.4 1 1 1h8c.6 0 1-.4 1-1v-1a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3Zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      />
    </svg>
    <div>{{ m.content }}</div>
  </div>
</template>
<script>
export default {
  name: "UserQuery",
  props: {
    m: {
      type: Object,
      required: true,
    },
  },
};
</script>
