<template>
  <div class="pt-5">
    <bread-crumb :items="bitems"></bread-crumb>
    <div class="flex flex-row justify-between">
      <h1 class="text-3xl text-gray-800 font-semibold mb-5">
        <svg
          class="w-9 h-9 text-gray-800 inline-block"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 12v1h4v-1m4 7H6a1 1 0 0 1-1-1V9h14v9a1 1 0 0 1-1 1ZM4 5h16a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Z"
          />
        </svg>
        Available indexes
      </h1>
      <router-link
        v-if="key.key"
        to="/index/create"
        class="text-white bg-lime-500 h-8 hover:bg-lime-800 rounded-lg text-sm px-2 py-1 mr-5"
      >
        + Add new index
      </router-link>
    </div>
    <div class="mt-5">
      <div
        v-if="!key.key && !load"
        class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50"
        role="alert"
      >
        <span class="font-medium">OpenAI key error!</span>
        For correct operation, you must enter the API key to OpenAi.
        <router-link to="/settings" class="underline"
          >Go to Settings</router-link
        >
      </div>
      <div
        class="relative overflow-x-auto shadow-md sm:rounded-lg"
        v-if="indexes.length"
      >
        <table
          class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
        >
          <thead
            class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
          >
            <tr>
              <th scope="col" class="px-6 py-3">Index name</th>
              <th scope="col" class="px-6 py-3">Status</th>
              <th scope="col" class="px-6 py-3">Chunks count</th>
              <th scope="col" class="px-6 py-3">Chunk size / overlap</th>
              <th scope="col" class="px-6 py-3">Sources</th>
              <th scope="col" class="px-6 py-3">Last updated</th>
              <th scope="col" class="px-6 py-3">
                <span class="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(index, si) in indexes"
              v-bind:key="si"
              class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            >
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                {{ index.name }}
              </th>
              <td class="px-6 py-4">
                <div class="flex items-center first-letter:uppercase">
                  <div
                    class="h-2.5 w-2.5 rounded-full me-2"
                    :class="
                      index.status === 'complete'
                        ? 'bg-green-500'
                        : 'bg-yellow-300'
                    "
                  ></div>
                  {{ index.status }}
                </div>
              </td>
              <td class="px-6 py-4">{{ index.chunks_count }}</td>
              <td class="px-6 py-4 font-bold">
                {{ index.chunk_size }} / {{ index.chunk_overlap }}
              </td>
              <td class="px-6 py-4">
                <span
                  v-for="(source, si) in index.sources"
                  v-bind:key="si"
                  class="mr-1 py-0.5 px-1 bg-blue-200 rounded-md"
                >
                  {{ getSourcesName(source.source_id) }}
                </span>
              </td>
              <td class="px-6 py-4">{{ index.updated_at }}</td>
              <td class="px-6 py-4 text-right">
                <a
                  href="#"
                  @click.prevent="deleteIndex(index.id)"
                  class="font-medium text-red-600 hover:underline"
                  >Delete</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="w-full text-center p-5" v-if="!indexes.length && key.key">
        <router-link
          to="/index/create"
          class="text-white bg-lime-500 hover:bg-lime-800 rounded-lg text-md px-3 py-2 mt-5"
        >
          Create first Index
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../../api";
import BreadCrumb from "../BreadCrumb.vue";
import _ from "lodash";
export default {
  name: "IndexList",
  data: function () {
    return {
      indexes: [],
      sources: [],
      user: null,
      load: true,
      key: { key: null },
      bitems: [
        {
          text: "Index list",
          link: "/index",
        },
      ],
    };
  },
  components: {
    BreadCrumb,
  },
  beforeMount() {
    this.getSources();
    this.getIndexes();
    this.getUser();
  },
  methods: {
    getUser() {
      let that = this;
      api.get("/api/account/get").then((r) => {
        if (r.data) {
          that.load = false;
          that.user = r.data.user;
          that.key = r.data.key;
        }
      });
    },
    getSources() {
      let self = this;
      api.get("/api/source/list").then((r) => {
        if (r.data) {
          self.sources = r.data.sources;
        }
      });
    },
    getIndexes() {
      let self = this;
      api.get("/api/index/list").then((r) => {
        if (r.data) {
          self.indexes = r.data.indexes;
        }
      });
    },
    getSourcesName(source) {
      let source_ob = _.find(this.sources, { id: source });
      if (source_ob) {
        return source_ob.name;
      }
    },
    deleteIndex(id) {
      let self = this;
      api.delete("/api/index/" + id).then((r) => {
        if (r.data) {
          self.getIndexes();
        }
      });
    },
  },
};
</script>
