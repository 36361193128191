<template>
  <div>
    <h3 class="text-xl text-gray-800">
      <svg
        class="w-6 h-6 text-gray-800 dark:text-white inline-block"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M10 3v4a1 1 0 0 1-1 1H5m4 8h6m-6-4h6m4-8v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1Z"
        />
      </svg>
      Retrived documents
    </h3>
    <div
      v-if="(!documents || !documents.length) && !loader"
      class="italic text-xl text-gray-500 text-center pt-10"
    >
      No documents found
    </div>
    <div
      class="text-xs text-gray-500"
      v-if="documents && documents.length && !loader"
    >
      <a
        href="#"
        class="underline decoration-dashed text-orange-600 text-right inline-block w-full"
        @click.prevent="showDebug = !showDebug"
        >Show search debug</a
      >
      <div class="mt-2 mb-5 p-2 bg-white" v-if="showDebug">
        <b>Routiing answer:</b> <br />
        {{ routing }}
      </div>
    </div>
    <div role="status" v-if="loader" class="text-center mt-10">
      <svg
        aria-hidden="true"
        class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span class="sr-only">Loading...</span>
    </div>
    <ul class="mt-3 overflow-auto max-h-[400px] min-h-[340px]">
      <li
        class="cursor-pointer hover:bg-gray-300 rounded-sm p-2 mb-2 border-b border-gray-300 bg-white"
        v-for="(doc, di) in documents"
        @click="showDoc(doc)"
        v-bind:key="di"
      >
        <div class="flex flex-row">
          <svg
            v-if="doc.in_prompt"
            class="mt-1 mr-2 w-5 h-5 p-0 font-normal text-lime-600"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fill-rule="evenodd"
              d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-7Z"
              clip-rule="evenodd"
            />
          </svg>

          <svg
            v-else
            class="mt-1 mr-2 w-5 h-5 font-normal text-blue-500"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 3v4a1 1 0 0 1-1 1H5m14-4v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1Z"
            />
          </svg>
          <div class="pt-1 text-md font-normal text-gray-900 basis-9/12">
            {{ doc.title.substring(0, 30) }}
            <span v-if="doc.title.length > 30">...</span>
          </div>
          <div class="flex flwx-row">
            <fwb-tooltip placement="left" theme="light">
              <template #trigger>
                <div
                  class="bg-blue-100 py-0.5 px-1.5 ml-2 rounded-lg text-sm inline-block h-6"
                >
                  {{ doc.relevance.toFixed(4) }}
                </div>
              </template>
              <template #content>
                <div
                  class="p-2 rounded-md bg-white border border-gray-800 shadow-lg text-sm"
                >
                  <div class="mb-4">
                    <div class="mb-2">
                      Keyword score:
                      <span class="font-bold">
                        {{ doc.match_features.keyword_score }}
                      </span>
                    </div>
                    <div class="text-xs pl-3">
                      Content score:
                      {{ doc.match_features["bm25(content)"] }}
                    </div>
                    <div class="text-xs pl-3">
                      Title score:
                      {{ doc.match_features["bm25(title)"] }}
                    </div>
                  </div>
                  <div class="mb-2">
                    <div class="mb-2">
                      Vector score:
                      <span class="font-bold">
                        {{ doc.match_features.vector_score }}
                      </span>
                    </div>
                    <div class="text-xs pl-3">
                      Content score:
                      {{ doc.match_features["closeness(field,embeddings)"] }}
                    </div>
                    <div class="text-xs pl-3">
                      Title score:
                      {{
                        doc.match_features["closeness(field,title_embedding)"]
                      }}
                    </div>
                  </div>
                  <div></div>
                </div>
              </template>
            </fwb-tooltip>
          </div>
        </div>
        <div class="text-sm font-light mt-1">
          {{ doc.content }}
        </div>
      </li>
    </ul>
    <fwb-modal v-if="isShowModal" @close="closeModal" size="5xl">
      <template #header>
        <div class="flex items-center text-lg">
          <span class="font-light inline-block mr-2">{{
            modal.updated_at
          }}</span>
          {{ modal.title }}
        </div>
      </template>
      <template #body>
        <p class="text-sm leading-relaxed text-gray-500 dark:text-gray-400">
          <vue-markdown :source="modal.content"></vue-markdown>
        </p>
        <div class="flex flex-row mt-5">
          <div
            class="bg-blue-200 text-blue-800 py-0.5 px-1.5 mr-2 rounded-lg text-sm inline-block h-6"
          >
            Score: <b>{{ modal.relevance.toFixed(5) }}</b>
          </div>
          <div
            class="bg-gray-200 py-0.5 px-1.5 rounded-lg text-sm inline-block h-6"
          >
            Source: <b>{{ modal.source_type }}</b>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex justify-between">
          <fwb-button @click="closeModal" color="alternative">
            Close
          </fwb-button>
        </div>
      </template>
    </fwb-modal>
  </div>
</template>
<script>
import { FwbButton, FwbModal, FwbTooltip } from "flowbite-vue";
import VueMarkdown from "vue-markdown-render";
import api from "../../api";
import _ from "lodash";
export default {
  name: "RetrivedDouments",
  components: {
    FwbButton,
    FwbModal,
    FwbTooltip,
    VueMarkdown,
  },
  props: {
    documents: {
      required: true,
    },
    loader: {
      default: false,
    },
    sources: {
      default: [],
    },
    routing: {
      default: null,
    },
    whereSql: {
      default: null,
    },
  },
  data: function () {
    return {
      showDebug: false,
      isShowModal: false,
      modal: { title: null, content: null, source_type: null, relevance: null },
    };
  },
  methods: {
    showDoc(doc) {
      let self = this;
      this.modal.relevance = doc.relevance;

      api
        .get("/api/chat/get_doc/" + doc.id, { validateStatus: () => true })
        .then((r) => {
          if (r.data.doc) {
            self.modal.title = r.data.doc.title;
            self.modal.content = r.data.doc.content;
            self.modal.source_links = r.data.doc.source_links;
            self.modal.source_type = self.getSourcesName(
              r.data.doc.source_type
            );
            self.modal.updated_at = r.data.doc.updated_at;
            self.isShowModal = true;
          }
        });
    },
    closeModal() {
      this.isShowModal = false;
    },
    getSourcesName(source) {
      let source_ob = _.find(this.sources, { id: source });
      if (source_ob) {
        return source_ob.name;
      }
    },
  },
};
</script>
