<template>
  <div id="dialog-w">
    <div v-for="(message, mi) in messages" v-bind:key="mi">
      <div :class="user_css" v-if="message.role === 'user'">
        <div
          class="relative w-9 h-9 overflow-hidden bg-red-100 rounded-full dark:bg-gray-600"
        >
          <svg
            class="absolute w-11 h-11 text-red-400 -left-1"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <div
          class="flex flex-col w-full leading-1.5 p-4 border-gray-200 bg-gray-100 rounded-e-xl rounded-es-xl dark:bg-gray-700"
        >
          <div class="flex items-center space-x-2 rtl:space-x-reverse">
            <span class="text-sm font-semibold text-gray-900 dark:text-white">
              You
            </span>
          </div>
          <p class="text-sm font-normal py-2.5 text-gray-900 dark:text-white">
            {{ message.data }}
          </p>
        </div>
      </div>
      <div
        :class="system_css"
        v-if="message.role === 'system' && message.is_garbage === true"
      >
        <svg
          class="w-6 h-6 text-red-800 inline-block"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 5.365V3m0 2.365a5.338 5.338 0 0 1 5.133 5.368v1.8c0 2.386 1.867 2.982 1.867 4.175 0 .593 0 1.193-.538 1.193H5.538c-.538 0-.538-.6-.538-1.193 0-1.193 1.867-1.789 1.867-4.175v-1.8A5.338 5.338 0 0 1 12 5.365Zm-8.134 5.368a8.458 8.458 0 0 1 2.252-5.714m14.016 5.714a8.458 8.458 0 0 0-2.252-5.714M8.54 17.901a3.48 3.48 0 0 0 6.92 0H8.54Z"
          />
        </svg>
        Is garbage
      </div>
      <div :class="assitant_css" v-if="message.role === 'llm'">
        <div
          class="flex flex-col w-full leading-1.5 p-4 border-gray-200 bg-blue-100 rounded-tl-xl rounded-b-xl dark:bg-gray-700"
        >
          <div class="flex items-center space-x-2 rtl:space-x-reverse">
            <span class="text-sm font-semibold text-gray-900 dark:text-white"
              >Assistant</span
            >
          </div>
          <p class="text-sm font-normal py-2.5 text-gray-900 dark:text-white">
            <vue-markdown :source="message.content"></vue-markdown>
          </p>
          <span class="text-sm font-normal text-gray-500 dark:text-gray-400">
            Spend tokens: <b>{{ tokens.total_tokens }}</b
            >&nbsp; <span>prompt: {{ tokens.prompt_tokens }}</span
            >&nbsp;
            <span>completion: {{ tokens.completion_tokens }}</span>
          </span>
          <a
            href="#"
            class="underline text-xs decoration-dashed"
            @click.prevent="togglePropmpt()"
            >Show prompt</a
          >
          <span class="text-xs text-gray-500" v-if="show_prompt">{{
            last_prompt
          }}</span>
        </div>
        <div
          class="relative w-9 h-9 overflow-hidden bg-blue-100 rounded-full dark:bg-gray-600"
        >
          <svg
            class="absolute w-8 h-8 text-blue-400 top-0.5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fill-rule="evenodd"
              d="M12 2a7 7 0 0 0-7 7 3 3 0 0 0-3 3v2a3 3 0 0 0 3 3h1a1 1 0 0 0 1-1V9a5 5 0 1 1 10 0v7.083A2.919 2.919 0 0 1 14.083 19H14a2 2 0 0 0-2-2h-1a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h1a2 2 0 0 0 1.732-1h.351a4.917 4.917 0 0 0 4.83-4H19a3 3 0 0 0 3-3v-2a3 3 0 0 0-3-3 7 7 0 0 0-7-7Zm1.45 3.275a4 4 0 0 0-4.352.976 1 1 0 0 0 1.452 1.376 2.001 2.001 0 0 1 2.836-.067 1 1 0 1 0 1.386-1.442 4 4 0 0 0-1.321-.843Z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueMarkdown from "vue-markdown-render";
export default {
  name: "ChatMessages",
  components: {
    VueMarkdown,
  },
  props: {
    messages: {
      required: true,
    },
    tokens: {
      default: null,
    },
    last_prompt: {
      default: null,
    },
  },
  data: function () {
    return {
      show_prompt: false,
      user_css: "flex items-start gap-2.5",
      assitant_css: "flex items-start gap-2.5 mt-5 mb-5",
      system_css: "p-2 bg-red-200 text-red-800 rounded-lg italic mt-5 w-1/2",
    };
  },
  methods: {
    togglePropmpt() {
      this.show_prompt = !this.show_prompt;
    },
  },
};
</script>
