<template>
  <div class="pt-5">
    <bread-crumb :items="bitems"></bread-crumb>
    <h1 class="text-3xl text-gray-800 font-semibold mb-5">
      <svg
        class="w-9 h-9 text-gray-800 inline-block"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M19 6c0 1.657-3.134 3-7 3S5 7.657 5 6m14 0c0-1.657-3.134-3-7-3S5 4.343 5 6m14 0v6M5 6v6m0 0c0 1.657 3.134 3 7 3s7-1.343 7-3M5 12v6c0 1.657 3.134 3 7 3s7-1.343 7-3v-6"
        />
      </svg>
      Create source data
    </h1>
    <div v-if="!type">
      <div class="border-b border-1 border-gray-200 py-5">
        <p>Import your knowledge data from various sources types.</p>
      </div>
      <div class="flex mt-8">
        <p class="font-medium text-xl">Available types</p>
      </div>
      <div class="flex flex-wrap gap-4 p-4">
        <div
          @click="choiseType('zip')"
          class="flex flex-col items-center justify-center p-4 rounded-lg w-40 cursor-pointer border border-gray-400 shadow-md hover:bg-gray-100"
        >
          <svg
            class="my-auto flex flex-shrink-0 text-link text-blue-800"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-width="2"
              d="M10 3v4a1 1 0 0 1-1 1H5m14-4v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1Zm-4 1h.01v.01H15V5Zm-2 2h.01v.01H13V7Zm2 2h.01v.01H15V9Zm-2 2h.01v.01H13V11Zm2 2h.01v.01H15V13Zm-2 2h.01v.01H13V15Zm2 2h.01v.01H15V17Zm-2 2h.01v.01H13V19Z"
            />
          </svg>
          <p class="text-md mt-3 font-normal tracking-tight text-gray-900">
            Upload ZIP
          </p>
        </div>
        <div
          @click="choiseType('json')"
          class="flex flex-col items-center justify-center p-4 rounded-lg w-40 cursor-pointer border border-gray-400 shadow-md hover:bg-gray-100"
        >
          <svg
            class="my-auto flex flex-shrink-0 text-link text-blue-800"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 3v4a1 1 0 0 1-1 1H5m5 4-2 2 2 2m4-4 2 2-2 2m5-12v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1Z"
            />
          </svg>
          <p class="text-md mt-3 font-normal tracking-tight text-gray-900">
            Upload JSON
          </p>
        </div>
        <div
          @click="choiseType('pdf')"
          class="flex flex-col items-center justify-center p-4 rounded-lg w-40 cursor-pointer border border-gray-400 shadow-md hover:bg-gray-100"
        >
          <svg
            class="my-auto flex flex-shrink-0 text-link text-violet-800"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fill-rule="evenodd"
              d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
              clip-rule="evenodd"
            />
          </svg>
          <p class="text-md mt-3 font-normal tracking-tight text-gray-900">
            Upload PDF file
          </p>
        </div>
        <div
          @click="choiseType('docx')"
          class="flex flex-col items-center justify-center p-4 rounded-lg w-40 cursor-pointer border border-gray-400 shadow-md hover:bg-gray-100"
        >
          <svg
            class="my-auto flex flex-shrink-0 text-link text-blue-600"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              d="M6 16v-3h.375a.626.626 0 0 1 .625.626v1.749a.626.626 0 0 1-.626.625H6Zm6-2.5a.5.5 0 1 1 1 0v2a.5.5 0 0 1-1 0v-2Z"
            />
            <path
              fill-rule="evenodd"
              d="M11 7V2h7a2 2 0 0 1 2 2v5h1a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1h-1a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2H3a1 1 0 0 1-1-1v-9a1 1 0 0 1 1-1h6a2 2 0 0 0 2-2Zm7.683 6.006 1.335-.024-.037-2-1.327.024a2.647 2.647 0 0 0-2.636 2.647v1.706a2.647 2.647 0 0 0 2.647 2.647H20v-2h-1.335a.647.647 0 0 1-.647-.647v-1.706a.647.647 0 0 1 .647-.647h.018ZM5 11a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 9 15.375v-1.75A2.626 2.626 0 0 0 6.375 11H5Zm7.5 0a2.5 2.5 0 0 0-2.5 2.5v2a2.5 2.5 0 0 0 5 0v-2a2.5 2.5 0 0 0-2.5-2.5Z"
              clip-rule="evenodd"
            />
            <path
              d="M9 7V2.221a2 2 0 0 0-.5.365L4.586 6.5a2 2 0 0 0-.365.5H9Z"
            />
          </svg>
          <p class="text-md mt-3 font-normal tracking-tight text-gray-900">
            Upload DOCx file
          </p>
        </div>
        <div
          @click="choiseType('youtube')"
          class="flex flex-col items-center justify-center p-4 rounded-lg w-40 cursor-pointer border border-gray-400 shadow-md hover:bg-gray-100"
        >
          <svg
            class="my-auto flex flex-shrink-0 text-link text-pink-800"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fill-rule="evenodd"
              d="M21.7 8.037a4.26 4.26 0 0 0-.789-1.964 2.84 2.84 0 0 0-1.984-.839c-2.767-.2-6.926-.2-6.926-.2s-4.157 0-6.928.2a2.836 2.836 0 0 0-1.983.839 4.225 4.225 0 0 0-.79 1.965 30.146 30.146 0 0 0-.2 3.206v1.5a30.12 30.12 0 0 0 .2 3.206c.094.712.364 1.39.784 1.972.604.536 1.38.837 2.187.848 1.583.151 6.731.2 6.731.2s4.161 0 6.928-.2a2.844 2.844 0 0 0 1.985-.84 4.27 4.27 0 0 0 .787-1.965 30.12 30.12 0 0 0 .2-3.206v-1.516a30.672 30.672 0 0 0-.202-3.206Zm-11.692 6.554v-5.62l5.4 2.819-5.4 2.801Z"
              clip-rule="evenodd"
            />
          </svg>
          <p class="text-md mt-3 font-normal tracking-tight text-gray-900">
            Youtube
          </p>
        </div>
        <div
          @click="choiseType('web')"
          class="flex flex-col items-center justify-center p-4 rounded-lg w-40 cursor-pointer border border-gray-400 shadow-md hover:bg-gray-100"
        >
          <svg
            class="my-auto flex flex-shrink-0 text-link text-blue-800"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961"
            />
          </svg>
          <p class="text-md mt-3 font-normal tracking-tight text-gray-900">
            Web site / Url
          </p>
        </div>
      </div>
    </div>
    <div v-if="type != null">
      <div class="border-b border-1 border-gray-200 py-5 mb-3">
        <p>
          <a
            href="#"
            @click.prevent="type = null"
            class="inline-flex items-center font-medium text-gray-600 dark:text-gray-500 hover:underline"
          >
            <svg
              class="w-4 h-4 ms-2 mr-2 rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
            Return to the type selection menu
          </a>
        </p>
      </div>
      <upload-single v-if="type === 'pdf'" :ext_type="'.pdf'"></upload-single>
      <upload-single v-if="type === 'docx'" :ext_type="'.docx'"></upload-single>
      <upload-json v-if="type === 'json'"></upload-json>
      <upload-zip v-if="type === 'zip'"></upload-zip>
      <upload-web v-if="type === 'web'"></upload-web>
      <upload-youtube v-if="type === 'youtube'"></upload-youtube>
    </div>
  </div>
</template>
<script>
// import api from "../../api";
import BreadCrumb from "../BreadCrumb.vue";
import UploadZip from "./UploadZip.vue";
import UploadJson from "./UploadJson.vue";
import UploadSingle from "./UploadSingle.vue";
import UploadYoutube from "./UploadYoutube.vue";
import UploadWeb from "./UploadWeb.vue";
export default {
  name: "SourceCreate",
  data: function () {
    return {
      sources: [],
      bitems: [
        {
          text: "Sources list",
          link: "/sources",
        },
        {
          text: "Create source",
          link: "/sources/create",
        },
      ],
      type: null,
    };
  },
  components: {
    BreadCrumb,
    UploadZip,
    UploadJson,
    UploadSingle,
    UploadYoutube,
    UploadWeb,
  },
  methods: {
    choiseType(type) {
      this.type = type;
    },
  },
};
</script>
