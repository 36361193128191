<template>
  <div class="flex flex-row p-0">
    <div class="basis-9/12 pt-5 relative min-h-[700px]">
      <h1 class="text-3xl text-gray-800 font-semibold mb-5">
        <svg
          class="w-9 h-9 text-gray-800 transition duration-75 group-hover:text-gray-900 inline-block"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 17h6l3 3v-3h2V9h-2M4 4h11v8H9l-3 3v-3H4V4Z"
          />
        </svg>
        Chat with assistant
      </h1>
      <div class="w-11/12 overflow-auto max-h-[450px] pb-5 pr-2">
        <div
          v-if="keyError"
          class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50"
          role="alert"
        >
          <span class="font-medium">OpenAI key error!</span>
          For correct operation, you must enter the API key to OpenAi.
          <router-link to="/settings" class="underline"
            >Go to Settings</router-link
          >
        </div>
        <chat-messages
          :messages="messages"
          :last_prompt="lastPropmpt"
          :tokens="tokens"
        ></chat-messages>
        <div class="w-full text-center mt-2" v-if="!isRunning">
          <button
            v-if="showAskLlm"
            @click="sendQuery"
            type="button"
            class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 rounded-lg text-sm px-2 py-1 mr-5"
          >
            Send prompt to LLM
          </button>
          <button
            v-if="messages && messages.length > 0"
            type="button"
            @click="clearTread"
            class="mt-3 text-white bg-red-500 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 rounded-lg text-sm px-2 py-1"
          >
            <svg
              class="w-3 h-3 text-white dark:text-white inline-block"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
              />
            </svg>
            Clear thread
          </button>
        </div>
        <div role="status" class="text-center mt-5" v-if="isRunning">
          <svg
            aria-hidden="true"
            class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="absolute bottom-0 pb-10 w-11/12">
        <div class="w-full">
          <label
            for="large-input"
            class="block mb-2 text-sm font-medium text-gray-900"
          >
            Your query:
            <small class="font-normal">(Ctrl + Enter to send)</small>
          </label>
          <input
            v-model="query"
            v-on:keypress.ctrl.enter="checkGarbage"
            id="large-input"
            class="block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:ring-gray-500 focus:border-gray-500"
            :class="queryError ? 'border border-red-600' : ''"
          />
        </div>
        <button
          type="button"
          @click="checkGarbage"
          class="absolute right-0 top-10 text-white bg-gray-800 hover:bg-gray-700 focus:ring-4 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2"
        >
          <svg
            class="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
          <span class="sr-only">Icon description</span>
        </button>
      </div>
    </div>
    <div class="p-4 pb-0 pr-0 basis-4/12 bg-gray-50">
      <retrived-documents
        :sources="sources"
        :documents="documents"
        :loader="documentLoad"
        :routing="routing"
        :whereSql="whereSql"
      ></retrived-documents>
      <div class="mt-4 p-2 pb-0">
        <h3 class="text-xl text-gray-800">
          <svg
            class="w-6 h-6 text-gray-800 dark:text-white inline-block"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 13v-2a1 1 0 0 0-1-1h-.757l-.707-1.707.535-.536a1 1 0 0 0 0-1.414l-1.414-1.414a1 1 0 0 0-1.414 0l-.536.535L14 4.757V4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v.757l-1.707.707-.536-.535a1 1 0 0 0-1.414 0L4.929 6.343a1 1 0 0 0 0 1.414l.536.536L4.757 10H4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h.757l.707 1.707-.535.536a1 1 0 0 0 0 1.414l1.414 1.414a1 1 0 0 0 1.414 0l.536-.535 1.707.707V20a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-.757l1.707-.708.536.536a1 1 0 0 0 1.414 0l1.414-1.414a1 1 0 0 0 0-1.414l-.535-.536.707-1.707H20a1 1 0 0 0 1-1Z"
            />
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
            />
          </svg>
          Settings
        </h3>
        <div class="mt-4">
          <div class="flex flex-row text-sm mb-3">
            <label
              for="small-input"
              class="block mt-2 text-sm font-medium text-gray-900 mr-3"
              >Assitant for search</label
            >
            <select
              v-model="assistant"
              @change="changeAssistant()"
              class="block w-1/4 p-2 text-gray-900 border border-gray-300 rounded-lg text-xs focus:ring-blue-500 focus:border-blue-500"
            >
              <option
                v-for="(assis, idxi) in assistants"
                :value="assis.id"
                v-bind:key="idxi"
              >
                {{ assis.name }}
              </option>
            </select>
          </div>
          <div class="flex flex-row text-sm mb-3">
            <label
              for="small-input"
              class="block mt-2 text-sm font-medium text-gray-900 mr-3"
              >Number documents in final prompt</label
            >
            <input
              type="text"
              v-model="chunksCount"
              id="small-input"
              class="block w-1/4 p-2 text-gray-900 border border-gray-300 rounded-lg text-xs focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div class="flex flex-row text-sm mb-3">
            <label
              for="small-input"
              class="block mt-2 text-sm font-medium text-gray-900 mr-3"
              >Title ratio</label
            >
            <input
              type="text"
              v-model="title_ratio"
              id="small-input"
              class="block w-1/4 p-2 text-gray-900 border border-gray-300 rounded-lg text-xs focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div class="flex flex-row text-sm">
            <label
              for="small-input"
              class="block mt-2 text-sm font-medium text-gray-900 mr-3"
              >Vector ratio</label
            >
            <input
              type="text"
              v-model="vector_ratio"
              id="small-input"
              class="block w-1/4 p-2 text-gray-900 border border-gray-300 rounded-lg text-xs focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RetrivedDocuments from "./RetrivedDocuments.vue";
import ChatMessages from "./ChatMessages.vue";
import api from "../../api";
import _ from "lodash";
export default {
  name: "ChatWindow",
  components: {
    RetrivedDocuments,
    ChatMessages,
  },
  data: function () {
    return {
      query: null,
      messages: [],
      documents: [],
      chunksCount: 4,
      vector_ratio: 4,
      title_ratio: 4,
      assistant: null,
      threadId: null,
      tokens: { total_tokens: 0, prompt_tokens: 0, completion_tokens: 0 },
      isRunning: false,
      showAskLlm: false,
      finalQuery: null,
      documentLoad: false,
      queryError: false,
      assistants: [],
      sources: [],
      lastPropmpt: null,
      routing: null,
      whereSql: null,
      keyError: false,
    };
  },
  beforeMount() {
    this.getAssistants();
    this.getSources();
  },
  methods: {
    getAssistants() {
      let self = this;
      api.get("/api/assistant/list").then((r) => {
        if (r.data) {
          if (r.data.assistants.length) {
            self.assistants = r.data.assistants;
            self.assistant = r.data.assistants[0].id;
            self.chunksCount = r.data.assistants[0].number_docs;
            self.title_ratio = r.data.assistants[0].title_ratio;
            self.vector_ratio = r.data.assistants[0].vector_ratio;
          }
        }
      });
    },
    changeAssistant() {
      let assist = _.find(this.assistants, { id: this.assistant });
      this.chunksCount = assist.number_docs;
      this.title_ratio = assist.title_ratio;
      this.vector_ratio = assist.vector_ratio;
    },
    getSources() {
      let self = this;
      api.get("/api/source/list").then((r) => {
        if (r.data) {
          self.sources = r.data.sources;
        }
      });
    },
    checkGarbage() {
      let self = this;
      if (!this.query) {
        this.queryError = true;
        return;
      } else {
        this.queryError = false;
      }
      self.finalQuery = self.query;
      self.query = null;
      self.documentLoad = true;
      self.searchDocuments();
    },
    searchDocuments() {
      let self = this;
      api
        .post(
          "/api/chat/search-documents",
          {
            query: self.finalQuery,
            assistant: self.assistant,
            chunks_count: self.chunksCount,
            title_ratio: self.title_ratio,
            vector_ratio: self.vector_ratio,
          },
          { validateStatus: () => true }
        )
        .then((r) => {
          if (r.status == 403) {
            self.documentLoad = false;
            self.keyError = true;
            console.log("key error");
          }
          if (r.status == 200) {
            self.messages.push({ role: "user", data: self.finalQuery });
            self.documents = r.data.documents;
            self.showAskLlm = true;
            self.documentLoad = false;
            self.routing = r.data.routing;
            self.whereSql = r.data.where;
          }
        });
    },
    sendQuery() {
      let self = this;
      let docs = [];
      self.isRunning = true;
      self.showAskLlm = false;
      self.documents.forEach((d) => {
        if (d.in_prompt) {
          docs.push(d.id);
        }
      });
      api
        .post("/api/chat/query", {
          query: self.finalQuery,
          thread: self.threadId,
          docs: docs,
          assistant: self.assistant,
        })
        .then((r) => {
          if (r.data) {
            self.isRunning = false;
            r.data.messages.forEach((m) => self.messages.push(m));
            self.tokens.completion_tokens = parseInt(
              r.data.tokens.completion_tokens
            );
            self.tokens.prompt_tokens = parseInt(r.data.tokens.prompt_tokens);
            self.tokens.total_tokens = parseInt(r.data.tokens.total_tokens);
            self.threadId = r.data.thread;
            self.lastPropmpt = r.data.llm_prompt;
          }
        });
    },
    clearTread() {
      this.query = null;
      this.messages = [];
      this.showAskLlm = false;
      this.isRunning - false;
      this.threadId = null;
      this.finalQuery = null;
      this.documents = [];
      this.documentLoad = false;
    },
  },
};
</script>
