<template>
  <div class="pt-5">
    <bread-crumb :items="bitems"></bread-crumb>
    <h1 class="text-3xl text-gray-800 font-semibold mb-5">
      <svg
        class="w-9 h-9 text-gray-800 inline-block mr-1"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M14.079 6.839a3 3 0 0 0-4.255.1M13 20h1.083A3.916 3.916 0 0 0 18 16.083V9A6 6 0 1 0 6 9v7m7 4v-1a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1Zm-7-4v-6H5a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h1Zm12-6h1a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-1v-6Z"
        />
      </svg>
      <span v-if="!assistant.id">Create assistant</span>
      <span v-else>Edit assistant</span>
    </h1>
    <div
      class="max-w-3xl shadow-md p-5 rounded-lg border-gray-100 border relative"
    >
      <div
        v-if="isCreated"
        role="status"
        class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
      >
        <svg
          aria-hidden="true"
          class="w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span class="sr-only">Loading...</span>
      </div>
      <div class="mb-6" :class="isCreated ? 'opacity-20' : ''">
        <input
          id="skip-title-checkbox"
          type="checkbox"
          v-model="assistant.is_active"
          class="w-4 h-4 text-blue-600 bg-white border-gray-300 rounded focus:ring-blue-500"
        />
        <label
          for="skip-title-checkbox"
          class="ms-2 text-md font-medium text-gray-900 dark:text-gray-300"
          >Active</label
        >
      </div>
      <div class="mb-6" :class="isCreated ? 'opacity-20' : ''">
        <label
          for="default-input"
          class="block mb-2 text-md font-medium text-gray-900 dark:text-white"
          >Asistant name</label
        >
        <input
          v-model="assistant.name"
          id="default-input"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          :class="nameError ? 'border-red-600' : ''"
        />
      </div>
      <div class="mb-6">
        <label
          for="chunk_size-input"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Number of documents in final prompt:</label
        >
        <input
          type="number"
          v-model="assistant.number_docs"
          id="chunk_size-input"
          aria-describedby="helper-text-explanation"
          class="w-4/12 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          required
        />
        <p class="mt-2 text-sm text-gray-500">
          How many documents will be included in the final request to LLM
        </p>
      </div>
      <div class="mb-6" :class="isCreated ? 'opacity-20' : ''">
        <div class="flex flex-row gap-5">
          <div>
            <label
              for="chunk_size-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >Title ratio:</label
            >
            <input
              type="number"
              v-model="assistant.title_ratio"
              id="chunk_size-input"
              aria-describedby="helper-text-explanation"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
          </div>
          <div>
            <label
              for="chunk_size-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >Vector ratio:</label
            >
            <input
              type="number"
              v-model="assistant.vector_ratio"
              id="chunk_size-input"
              aria-describedby="helper-text-explanation"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
          </div>
        </div>
        <p class="mt-2 text-sm text-gray-500">
          A number between 0 and 10, where 0 is not taken into account at all,
          10 is the greatest weight
        </p>
      </div>
      <div class="mb-6" :class="isCreated ? 'opacity-20' : ''">
        <label
          for="message"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Prompt for LLM</label
        >
        <textarea
          id="message"
          rows="4"
          v-model="assistant.prompt"
          class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
          :class="promptError ? 'border-red-600' : ''"
          placeholder="You're a support agent for the MyCompany service. ..."
        ></textarea>
      </div>
      <div class="mb-6" :class="isCreated ? 'opacity-20' : ''">
        <label
          for="sources"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Index</label
        >
        <div
          class="flex items-center mb-4"
          v-for="(index, si) in indexes"
          v-bind:key="si"
        >
          <input
            :id="index.id"
            type="checkbox"
            v-model="assistant.indexes"
            :value="index.id"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            :for="index.id"
            class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            {{ index.name }}
          </label>
        </div>
      </div>
      <div class="mb-6" :class="isCreated ? 'opacity-20' : ''">
        <button
          type="button"
          v-on:click="submitForm()"
          class="text-white bg-lime-500 hover:bg-lime-600 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
        >
          <span v-if="assistant.id">Update assistant</span>
          <span v-else>Create assistant</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "../BreadCrumb.vue";
import api from "../../api";
import _ from "lodash";
export default {
  name: "CreateIndex",
  data() {
    return {
      assistant: {
        id: null,
        name: null,
        prompt: null,
        is_active: true,
        skip_title: false,
        title_ratio: 5,
        vector_ratio: 5,
        number_docs: 1,
        indexes: [],
      },
      id: this.$route.params.id,
      bitems: [
        {
          text: "Asistants list",
          link: "/assistant",
        },
        {
          text: "Create assistant",
          link: "/assistant/create",
        },
      ],
      indexes: [],
      promptError: false,
      nameError: false,
      isCreated: false,
    };
  },
  components: {
    BreadCrumb,
  },
  beforeMount() {
    this.getIndexes();
    if (this.id && this.id.length) {
      this.getAssistant();
    }
  },
  methods: {
    getAssistant() {
      let self = this;
      api.get("/api/assistant/" + this.id).then((r) => {
        if (r.data) {
          self.assistant = r.data.assistant;
          self.assistant.indexes = _.map(r.data.assistant.indexes, "index_id");
        }
      });
    },
    getIndexes() {
      let self = this;
      api.get("/api/index/list").then((r) => {
        if (r.data) {
          self.indexes = r.data.indexes;
        }
      });
    },
    submitForm() {
      let self = this;
      this.promptError = false;
      this.nameError = false;
      if (!this.assistant.prompt || this.assistant.prompt.length == 0) {
        this.promptError = true;
        return;
      }
      if (!this.assistant.name || this.assistant.name.length == 0) {
        this.nameError = true;
        return;
      }
      if (this.assistant.id) {
        api
          .put("/api/assistant/" + this.assistant.id, this.assistant)
          .then((r) => {
            if (r.data) {
              self.$router.push("/assistant");
            }
          });
      } else {
        api.post("/api/assistant/", this.assistant).then((r) => {
          if (r.data) {
            self.$router.push("/assistant");
          }
        });
      }
    },
  },
};
</script>
