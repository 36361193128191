<template>
  <aside
    class="min-h-full transition-transform -translate-x-full sm:translate-x-0 pt-5 border-r border-gray-200"
  >
    <div class="px-3 h-full bg-white">
      <ul class="space-y-2">
        <li>
          <router-link
            to="/app"
            :active-class="'bg-gray-100 font-bold'"
            class="flex items-center p-2 text-md font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
          >
            <svg
              class="w-6 h-6 text-gray-400 transition duration-75 group-hover:text-blue-600"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 17h6l3 3v-3h2V9h-2M4 4h11v8H9l-3 3v-3H4V4Z"
              />
            </svg>

            <span class="ml-3">Chat</span>
          </router-link>
        </li>
      </ul>
      <ul
        class="pt-4 mt-4 space-y-2 font-medium border-t border-gray-200 dark:border-gray-700"
      >
        <li>
          <h3 class="flex items-center p-2 text-sm text-gray-600 rounded-lg">
            Knowledge base
          </h3>
        </li>
        <li>
          <router-link
            to="/assistant"
            :active-class="'bg-gray-100 font-bold'"
            class="flex items-center p-2 text-md font-normal text-gray-900 rounded-lg hover:bg-gray-100 group"
          >
            <svg
              class="w-6 h-6 text-gray-400 transition duration-75 group-hover:text-blue-600"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M14.079 6.839a3 3 0 0 0-4.255.1M13 20h1.083A3.916 3.916 0 0 0 18 16.083V9A6 6 0 1 0 6 9v7m7 4v-1a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1Zm-7-4v-6H5a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h1Zm12-6h1a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-1v-6Z"
              />
            </svg>
            <span class="ml-3">Assistants</span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/sources"
            :active-class="'bg-gray-100 font-bold'"
            class="flex items-center p-2 text-md font-normal text-gray-900 rounded-lg hover:bg-gray-100 group"
          >
            <svg
              class="w-6 h-6 text-gray-400 transition duration-75 group-hover:text-blue-600"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                d="M12 7.205c4.418 0 8-1.165 8-2.602C20 3.165 16.418 2 12 2S4 3.165 4 4.603c0 1.437 3.582 2.602 8 2.602ZM12 22c4.963 0 8-1.686 8-2.603v-4.404c-.052.032-.112.06-.165.09a7.75 7.75 0 0 1-.745.387c-.193.088-.394.173-.6.253-.063.024-.124.05-.189.073a18.934 18.934 0 0 1-6.3.998c-2.135.027-4.26-.31-6.3-.998-.065-.024-.126-.05-.189-.073a10.143 10.143 0 0 1-.852-.373 7.75 7.75 0 0 1-.493-.267c-.053-.03-.113-.058-.165-.09v4.404C4 20.315 7.037 22 12 22Zm7.09-13.928a9.91 9.91 0 0 1-.6.253c-.063.025-.124.05-.189.074a18.935 18.935 0 0 1-6.3.998c-2.135.027-4.26-.31-6.3-.998-.065-.024-.126-.05-.189-.074a10.163 10.163 0 0 1-.852-.372 7.816 7.816 0 0 1-.493-.268c-.055-.03-.115-.058-.167-.09V12c0 .917 3.037 2.603 8 2.603s8-1.686 8-2.603V7.596c-.052.031-.112.059-.165.09a7.816 7.816 0 0 1-.745.386Z"
              />
            </svg>

            <span class="ml-3">Sources</span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/index"
            :active-class="'bg-gray-100 font-bold'"
            class="flex items-center p-2 text-md font-normal text-gray-900 rounded-lg hover:bg-gray-100 group"
          >
            <svg
              class="w-6 h-6 text-gray-400 transition duration-75 group-hover:text-blue-600"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 12v1h4v-1m4 7H6a1 1 0 0 1-1-1V9h14v9a1 1 0 0 1-1 1ZM4 5h16a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Z"
              />
            </svg>

            <span class="ml-3">Indexes</span>
          </router-link>
        </li>
      </ul>
      <ul
        class="pt-4 mt-4 space-y-2 font-medium border-t border-gray-200 dark:border-gray-700"
      >
        <li>
          <h3 class="flex items-center p-2 text-sm text-gray-600 rounded-lg">
            Integrations
          </h3>
        </li>
        <li>
          <router-link
            to="/integrations/webhook"
            :active-class="'bg-gray-100 font-bold'"
            class="flex items-center p-2 text-md font-normal text-gray-900 rounded-lg hover:bg-gray-100 group"
          >
            <svg
              class="w-6 h-6 text-gray-400 transition duration-75 group-hover:text-blue-600 dark:group-hover:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M16.872 9.687 20 6.56 17.44 4 4 17.44 6.56 20 16.873 9.687Zm0 0-2.56-2.56M6 7v2m0 0v2m0-2H4m2 0h2m7 7v2m0 0v2m0-2h-2m2 0h2M8 4h.01v.01H8V4Zm2 2h.01v.01H10V6Zm2-2h.01v.01H12V4Zm8 8h.01v.01H20V12Zm-2 2h.01v.01H18V14Zm2 2h.01v.01H20V16Z"
              />
            </svg>
            <span class="ml-3">GPTs</span>
          </router-link>
        </li>
      </ul>
      <ul
        class="pt-4 mt-4 space-y-2 font-medium border-t border-gray-200 dark:border-gray-700"
      >
        <li>
          <h3 class="flex items-center p-2 text-sm text-gray-600 rounded-lg">
            Settings
          </h3>
        </li>
        <li>
          <router-link
            to="/settings"
            :active-class="'bg-gray-100 font-bold'"
            class="flex items-center p-2 text-md font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
          >
            <svg
              class="w-6 h-6 text-gray-400 transition duration-75 group-hover:text-blue-600 dark:group-hover:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 13v-2a1 1 0 0 0-1-1h-.757l-.707-1.707.535-.536a1 1 0 0 0 0-1.414l-1.414-1.414a1 1 0 0 0-1.414 0l-.536.535L14 4.757V4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v.757l-1.707.707-.536-.535a1 1 0 0 0-1.414 0L4.929 6.343a1 1 0 0 0 0 1.414l.536.536L4.757 10H4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h.757l.707 1.707-.535.536a1 1 0 0 0 0 1.414l1.414 1.414a1 1 0 0 0 1.414 0l.536-.535 1.707.707V20a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-.757l1.707-.708.536.536a1 1 0 0 0 1.414 0l1.414-1.414a1 1 0 0 0 0-1.414l-.535-.536.707-1.707H20a1 1 0 0 0 1-1Z"
              />
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
              />
            </svg>
            <span class="ml-3">Account settings</span>
          </router-link>
        </li>
      </ul>
    </div>
  </aside>
</template>
<script>
export default {
  name: "SideBar",
  props: {
    user: {
      required: true,
    },
  },
};
</script>
