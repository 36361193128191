import { createRouter, createWebHistory } from "vue-router";
import ChatWindow from "../components/chat/ChatWindow";

import IndexList from "../components/index/IndexList";
import CreateIndex from "../components/index/CreateIndex";

import SourcesList from "../components/source/SourcesList";
import SourceCreate from "../components/source/SourceCreate";

import AssistantList from "../components/assistant/AssistantList";
import AssistantCreate from "../components/assistant/AssistantCreate";

import WebhookList from "@/components/integrations/WebhookList.vue";
import WebhookCreate from "@/components/integrations/WebhookCreate.vue";

import AccountSettings from "@/components/settings/AccountSettings.vue";

import StoicPlayground from "../components/StoicPlayground";
import KeysList from "../components/keys/KeysList";
import CollectionsList from "../components/collections/CollectionsList";
import DebugPlayground from "@/components/DebugPlayground.vue";

const routes = [
  {
    path: "/",
    name: "app",
    component: ChatWindow,
  },
  {
    path: "/app",
    name: "app",
    component: ChatWindow,
  },
  {
    path: "/assistant",
    name: "assistants_list",
    component: AssistantList,
  },
  {
    path: "/assistant/create",
    name: "assistant_create",
    component: AssistantCreate,
  },
  {
    path: "/assistant/:id",
    name: "assistant_update",
    component: AssistantCreate,
  },
  {
    path: "/index",
    name: "index",
    component: IndexList,
  },
  {
    path: "/index/create",
    name: "create_index",
    component: CreateIndex,
  },
  {
    path: "/sources",
    name: "sources",
    component: SourcesList,
  },
  {
    path: "/sources/create",
    name: "create_source",
    component: SourceCreate,
  },
  {
    path: "/integrations/webhook",
    name: "webhook",
    component: WebhookList,
  },
  {
    path: "/integrations/webhook/create",
    name: "webhook_create",
    component: WebhookCreate,
  },
  {
    path: "/integrations/webhook/:id",
    name: "webhook_update",
    component: WebhookCreate,
  },
  {
    path: "/settings",
    name: "settings",
    component: AccountSettings,
  },
  {
    path: "/stoic",
    name: "home",
    component: StoicPlayground,
  },
  {
    path: "/debug",
    name: "debug",
    component: DebugPlayground,
  },
  {
    path: "/collections",
    name: "collections",
    component: CollectionsList,
  },
  {
    path: "/keys",
    name: "keys",
    component: KeysList,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
