<template>
  <div
    class="w-full mx-auto max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700"
  >
    <form class="space-y-6" action="#" v-if="registerForm">
      <h5 class="text-xl font-medium text-gray-900 dark:text-white">
        Sign in to our platform
      </h5>
      <div>
        <label
          for="name"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Your Name</label
        >
        <input
          type="text"
          v-model="user.name"
          id="name"
          class="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
          :class="nameError ? 'border-red-400' : 'border-gray-300'"
          @focus="nameError = false"
          placeholder=""
          required
        />
      </div>
      <div>
        <label
          for="email"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Your email</label
        >
        <input
          type="email"
          v-model="user.email"
          @focus="(emailError = false), (alreadyError = false)"
          id="email"
          :class="emailError ? 'border-red-400' : 'border-gray-300'"
          class="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
          placeholder="name@company.com"
          required
        />
      </div>
      <div>
        <label
          for="password"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Your password</label
        >
        <input
          type="password"
          v-model="user.password"
          id="password"
          placeholder="••••••••"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
          required
        />
      </div>
      <div>
        <label
          for="confirm_password"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Confirm password</label
        >
        <input
          type="password"
          v-model="user.confirm_password"
          id="confirm_password"
          placeholder="••••••••"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
          required
        />
      </div>
      <div
        v-if="passError"
        class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
        role="alert"
      >
        <span class="font-medium">Password</span> and
        <span class="font-medium">confirm password</span> don't match.
      </div>
      <div
        v-if="alreadyError"
        class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
        role="alert"
      >
        User with email
        <span class="font-medium">{{ this.user.email }}</span> already existst.
      </div>
      <button
        type="button"
        @click.prevent="register()"
        class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        Create account
      </button>
      <div class="text-sm font-medium text-gray-500 dark:text-gray-300">
        Already registered?
        <a
          href="#"
          @click.prevent="registerForm = false"
          class="text-blue-700 hover:underline dark:text-blue-500"
          >Log in</a
        >
      </div>
    </form>
    <form class="space-y-6" action="#" v-else>
      <h5 class="text-xl font-medium text-gray-900 dark:text-white">
        Sign in to our platform
      </h5>
      <div>
        <label
          for="email"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Your email</label
        >
        <input
          type="email"
          id="email"
          v-model="login.email"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
          placeholder="name@company.com"
          required
        />
      </div>
      <div>
        <label
          for="password"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Your password</label
        >
        <input
          type="password"
          v-model="login.password"
          id="password"
          placeholder="••••••••"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
          required
        />
      </div>
      <div
        v-if="loginError"
        class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
        role="alert"
      >
        Invalid credentials
      </div>
      <button
        type="button"
        @click.prevent="loginUser()"
        class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        Login to your account
      </button>
      <div class="text-sm font-medium text-gray-500 dark:text-gray-300">
        Not registered?
        <a
          href="#"
          @click.prevent="registerForm = true"
          class="text-blue-700 hover:underline dark:text-blue-500"
          >Create account</a
        >
      </div>
    </form>
  </div>
</template>
<script>
import api from "../../api";
export default {
  name: "LoginForm",
  data() {
    return {
      registerForm: false,
      user: { name: null, email: null, password: null, confirm_password: null },
      login: { email: null, password: null },
      nameEror: false,
      emailError: false,
      passError: false,
      alreadyError: false,
      loginError: false,
    };
  },
  methods: {
    loginUser() {
      let that = this;
      api
        .post("/api/auth/login", this.login)
        .then((r) => {
          localStorage.setItem("access_token", r.data.access_token);
          window.location.href = "/app";
        })
        .catch(function () {
          that.loginError = true;
        });
    },
    register() {
      let that = this;
      if (this.validate()) {
        api
          .post("/api/auth/register", this.user)
          .then((r) => {
            localStorage.setItem("access_token", r.data.access_token);
            window.location.href = "/app";
          })
          .catch(function () {
            that.alreadyError = true;
          });
      }
    },
    validate() {
      let valid = true;
      if (!this.user.name || !this.user.name.length) {
        this.nameError = true;
        valid = false;
      }
      if (!this.user.email || !this.user.email.length) {
        this.emailError = true;
        valid = false;
      }
      if (
        !this.user.password ||
        !this.user.confirm_password ||
        this.user.password !== this.user.confirm_password
      ) {
        this.passError = true;
        valid = false;
      }
      return valid;
    },
  },
};
</script>
