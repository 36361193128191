<template>
  <div class="pr-5 pt-5">
    <h1 class="text-4xl text-gray-800 font-semibold mb-5">Playground</h1>
    <div class="mb-5 bg-gray-10 min-h-80">
      <div v-for="(m, k) in messages" v-bind:key="k">
        <assistant-reposnse :m="m" v-if="m.role === 'llm'"></assistant-reposnse>
        <user-query :m="m" v-if="m.role === 'query'"></user-query>
        <debug-meassage
          :m="m"
          v-if="
            (m.role === 'user' ||
              m.role === 'vector' ||
              m.role === 'instruction' ||
              m.role === 'article' ||
              m.role === 'check_garbage' ||
              m.role === 'user_query' ||
              m.role === 'rephrase') &&
            showDebug
          "
        ></debug-meassage>
        <div class="ml-20 mt-3 mb-3" v-if="m.role === 'poll'">
          <button
            @click="sendQuery"
            type="button"
            class="text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-3 py-1.5 me-2 mt-2"
          >
            Go next
          </button>
        </div>
      </div>

      <div class="ml-20 mt-3" v-if="showSendLlm">
        <button
          @click="stopQuery"
          type="button"
          class="text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-3 py-1.5 me-2 mt-2"
        >
          Stop and enter new query
        </button>
        <button
          @click="requestLlm"
          type="button"
          class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-3 py-1.5 me-2 mt-2"
        >
          Send prompt to LLM
        </button>
      </div>
      <div class="text-center" v-if="isRunning">
        <div role="status">
          <svg
            aria-hidden="true"
            class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <button
      type="button"
      @click="clearThread"
      class="text-white float-right bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-xsm px-3 py-1.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
    >
      <svg
        class="w-4 h-4 text-white dark:text-white inline-block"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
        />
      </svg>
      Clear chat history
    </button>
    <form class="w-full">
      <label
        for="message"
        class="w-full block mb-2 font-medium text-gray-900 dark:text-white"
        >Your query</label
      >
      <textarea
        id="message"
        v-model="query"
        v-on:keypress.ctrl.enter="sendQuery"
        rows="4"
        class="block p-2.5 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
        placeholder="exmaple: How can I share the bot with friends?"
      ></textarea>
      <button
        v-if="!isRunning"
        @click="sendQuery"
        type="button"
        class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg px-5 py-2.5 me-2 mt-2"
      >
        Send
      </button>
      <button
        v-else
        disabled
        type="button"
        class="py-2.5 px-5 me-2 font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 mt-2"
      >
        <svg
          aria-hidden="true"
          role="status"
          class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="#1C64F2"
          />
        </svg>
        Loading...
      </button>
    </form>
    <div
      class="p-4 mb-1 mt-4 text-blue-800 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-blue-400 text-right"
      role="alert"
    >
      <a
        href="#"
        class="float-start underline underline-offset-4"
        v-if="!showDebug"
        @click.prevent="showDebug = true"
      >
        <svg
          class="w-4 h-4 text-blue-600 dark:text-white inline-block"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            stroke-width="2"
            d="M21 12c0 1.2-4 6-9 6s-9-4.8-9-6c0-1.2 4-6 9-6s9 4.8 9 6Z"
          />
          <path
            stroke="currentColor"
            stroke-width="2"
            d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
          />
        </svg>
        Show debug info
      </a>
      <a
        href="#"
        @click.prevent="showDebug = false"
        class="float-start underline underline-offset-4"
        v-else
      >
        <svg
          class="w-4 h-4 text-blue-600 dark:text-white inline-block"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 14c-.5-.6-.9-1.3-1-2 0-1 4-6 9-6m7.6 3.8A5 5 0 0 1 21 12c0 1-3 6-9 6h-1m-6 1L19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
          />
        </svg>
        Hide debug info
      </a>

      <span class="inline-block mr-2">Total tokens spent per dialogue:</span>
      <span
        class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300"
        >Prompt: {{ tokens.prompt_tokens }}</span
      >
      <span
        class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300"
        >Completion: {{ tokens.completion_tokens }}</span
      >
      <span
        class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300"
        >Total: {{ tokens.total_tokens }}</span
      >
    </div>
    <div class="p-4 mb-1 mt-4 text-gray-600 rounded-sm bg-gray-50" role="alert">
      <h3 class="text-xl mb-2">Settings</h3>
      <div class="mb-3 mt-2">
        Select vector collection:
        <select
          v-model="use_collection"
          @change="collection_error = false"
          class="ml-3 inline-block text-gray-900 border border-gray-300 rounded-lg"
          :class="collection_error ? 'border-red-800 border-2' : ''"
        >
          <option
            v-for="(c, ck) in collecitons"
            v-bind:key="ck"
            :value="c.name"
          >
            {{ c.name }} ({{ c.cmetadata.description }})
          </option>
        </select>
      </div>
      <div class="mb-3 mt-2">
        RAG instruction:<br />
        <textarea
          id="propmt"
          v-model="rag_prompt"
          rows="4"
          class="block p-2.5 mt-2 w-full text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
        ></textarea>
      </div>
      <div class="flex justify-normal w-full mt-4 mb-4">
        <div>
          Search chunks count:
          <input
            type="text"
            v-model="vectorCount"
            size="2"
            class="inline-block ml-1 text-center p-1 text-gray-900 border border-gray-300 w-10 rounded-lg"
          />
        </div>
        <div class="ml-4">
          Number of articles used in finnal prompt:
          <input
            type="text"
            v-model="promptCount"
            size="2"
            class="inline-block ml-1 text-center p-1 text-gray-900 border border-gray-300 w-10 rounded-lg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../api";
import AssistantReposnse from "../views/AssistantReposnse.vue";
import DebugMeassage from "../views/DebugMeassage.vue";
import UserQuery from "../views/UserQuery.vue";
export default {
  name: "DebugPlayground",
  components: {
    DebugMeassage,
    UserQuery,
    AssistantReposnse,
  },
  data: function () {
    return {
      query: null,
      thread: null,
      isRunning: false,
      showDebug: true,
      tokens: { total_tokens: 0, prompt_tokens: 0, completion_tokens: 0 },
      messages: [],
      vectorCount: 4,
      promptCount: 1,
      thresholding: 0.1,
      collecitons: [],
      use_collection: null,
      collection_error: false,
      finnalQuery: null,
      showSendLlm: false,
      rephrased: null,
      rag_prompt:
        "You're a support agent for the Manychat.com service. Use the article in user queries. " +
        "Provide pictures from article if it's possible, also return links to the source of the article.. Return answer in valid Markdown format. " +
        'If the answer cannot be found, write "I don\'t know."',
    };
  },
  beforeMount() {
    this.getCollections();
  },
  methods: {
    getCollections() {
      let self = this;
      api.get("/api/collections").then((r) => {
        if (r.data) {
          self.collecitons = r.data.collections;
        }
      });
    },
    sendQuery() {
      let that = this;
      this.finnalQuery = this.query;
      let rephrased = null;
      if (that.use_collection === null) {
        that.collection_error = true;
        return;
      }
      that.isRunning = true;
      that.query = null;
      that.messages.push({
        role: "query",
        content: this.finnalQuery,
      });

      that.is_garbage = false;
      api
        .post("/api/check_garbage", {
          query: that.finnalQuery,
        })
        .then((r) => {
          if (r.data) {
            that.messages.push(r.data);
            if (r.data.content !== "GARBAGE") {
              api
                .post("/api/rephrase", {
                  query: that.finnalQuery,
                  thread_id: this.thread,
                }) // rephrase query
                .then((r) => {
                  if (r.data) {
                    that.messages.push(r.data);
                    that.rephrased =
                      r.data.tokens_count > 0 ? r.data.content : null; // if isset rephrased query
                    that.finnalQuery = rephrased ?? that.finnalQuery;
                    api
                      .post("/api/vector", {
                        query: that.finnalQuery,
                        chunks_count: that.vectorCount,
                        prompt_count: that.promptCount,
                        collection_name: that.use_collection,
                      })
                      .then((r) => {
                        // show vector results
                        if (r.data) {
                          that.messages.push(r.data);
                          api
                            .post("/api/rag-prompt", {
                              query: that.finnalQuery,
                              collection_name: that.use_collection,
                              rag_prompt: that.rag_prompt,
                              chunks_count: that.vectorCount,
                              prompt_count: that.promptCount,
                            })
                            .then((r) => {
                              if (r.data) {
                                r.data.messages.forEach((m) =>
                                  that.messages.push(m)
                                );
                                that.showSendLlm = true;
                              }
                            });
                        }
                      });
                  }
                });
            } else {
              that.messages.push({
                content: "Garbage detected",
                role: "llm",
                tokens: {
                  prompt_tokens: 0,
                  completion_tokens: 0,
                  total_tokens: 0,
                },
              });
              that.stopQuery();
            }
          }
        });
    },
    stopQuery() {
      this.isRunning = false;
      this.showSendLlm = false;
      this.finnalQuery = null;
      this.rephrased = null;
    },
    requestLlm() {
      let that = this;
      that.showSendLlm = false;
      api
        .post("/api/playground", {
          query: that.finnalQuery,
          thread_id: that.thread,
          rephrased_query: that.rephrased,
          chunks_count: that.vectorCount,
          prompt_count: that.promptCount,
          collection_name: that.use_collection,
          rag_prompt: that.rag_prompt,
        }) // query to open
        .then((r) => {
          if (r.data) {
            r.data.messages.forEach((m) => that.messages.push(m));
            that.tokens.completion_tokens += parseInt(
              r.data.tokens.completion_tokens
            );
            that.tokens.prompt_tokens += parseInt(r.data.tokens.prompt_tokens);
            that.tokens.total_tokens += parseInt(r.data.tokens.total_tokens);
            that.thread = r.data.thread;
            that.isRunning = false;
          }
        });
    },
    clearThread() {
      this.thread = null;
      this.messages = [];
      this.rephrased = null;
      this.finnalQuery = null;
      this.isRunning = false;
      this.tokens = { total_tokens: 0, prompt_tokens: 0, completion_tokens: 0 };
    },
  },
};
</script>
