import axios from "axios";
const api = axios.create({
  // baseURL: "http://127.0.0.1:5000/app/",
  baseURL: "https://apteka.ordemio.com/app/",
});
api.interceptors.request.use((config) => {
  if (localStorage.getItem("access_token")) {
    config.headers.authorization =
      "Bearer " + localStorage.getItem("access_token");
    config.headers.accept = "application/json";
  }

  return config;
}, {});
api.interceptors.response.use({}, (error) => {
  if (error.response.data.msg === "Token has expired") {
    return axios
      .post(
        "/api/auth/refresh",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      )
      .then(
        (resp) => {
          localStorage.setItem("access_token", resp.data.authorisation.token);

          error.config.headers.authorization =
            "Bearer " + resp.data.authorisation.token;

          return api.request(error.config);
        },
        (error) => {
          console.log(error.data);
          localStorage.removeItem("access_token");
          let url = new URL("/login", window.location.origin);
          window.location.href = url.toString();
        }
      );
  }
  if (error.response.data.error === "Token not provided") {
    localStorage.removeItem("access_token");
    let url = new URL("/login", window.location.origin);
    window.location.href = url.toString();
  }
});

export default api;
