<template>
  <div class="ml-20 mt-2">
    <div
      v-if="message.role === 'instruction'"
      @click="message.open = !message.open"
      class="cursor-pointer p-4 text-sm text-gray-800 rounded-sm bg-gray-50 dark:bg-gray-800 dark:text-gray-300"
    >
      <h3 class="flex font-bold">Instrunction:</h3>
      <span v-if="message.open">{{ message.content }}</span>
      <span v-else>Click to show...</span>
    </div>
    <div
      v-if="message.role === 'poll'"
      class="cursor-pointer p-4 text-gray-800 rounded-sm bg-gray-50 dark:bg-gray-800 dark:text-gray-300"
    >
      <h3 class="flex font-bold">Poll query:</h3>
      <span>{{ message.content }}</span>
    </div>
    <div
      v-if="message.role === 'check_garbage'"
      class="cursor-pointer p-4 text-gray-800 rounded-sm bg-gray-50 dark:bg-gray-800 dark:text-gray-300"
    >
      <h3 class="flex font-bold">Check garbage:</h3>
      <span>{{ message.content }}</span>
    </div>
    <div
      v-if="message.role === 'article'"
      @click="message.open = !message.open"
      class="cursor-pointer p-4 text-sm text-gray-800 rounded-sm bg-gray-50 dark:bg-gray-800 dark:text-gray-300"
    >
      <h3 class="flex font-bold">Articles:</h3>
      <span v-if="message.open" class="debug-articles">
        <vue-markdown :source="message.content"></vue-markdown>
      </span>
      <span v-else>Click to show...</span>
    </div>
    <div
      v-if="message.role === 'user_query'"
      @click="message.open = !message.open"
      class="cursor-pointer p-4 text-sm text-gray-800 rounded-sm bg-gray-50 dark:bg-gray-800 dark:text-gray-300"
    >
      <h3 class="flex font-bold">Query:</h3>
      <span v-if="message.open">{{ message.content }}</span>
      <span v-else>Click to show...</span>
    </div>
    <div
      v-if="message.role === 'user'"
      @click="message.open = !message.open"
      class="cursor-pointer p-4 text-sm text-gray-800 rounded-sm bg-gray-50 dark:bg-gray-800 dark:text-gray-300"
    >
      <h3 class="flex font-bold">Full prompt to OpenAi:</h3>
      <span v-if="message.open">{{ message.content }}</span>
      <span v-else>Click to show...</span>
    </div>
    <div
      v-if="message.role === 'rephrase' && message.tokens_count > 0"
      class="p-4 text-sm text-gray-800 rounded-sm bg-gray-50"
    >
      <p>
        <b>Rephrase prompt:</b> {{ message.rephrase_prompt }} <br /><br />
        <b>Result query:</b> {{ message.content }}
      </p>
      <span
        v-if="message.role === 'rephrase'"
        class="bg-gray-200 text-gray-800 font-medium me-2 px-2.5 py-0.5 rounded inline-block mt-1"
        >Spend tokens: {{ message.tokens_count }}</span
      >
    </div>
    <div
      v-if="message.role === 'vector'"
      class="p-4 text-sm text-gray-800 rounded-sm bg-gray-50 dark:bg-gray-800 dark:text-gray-300"
    >
      <h3 class="flex">
        <b v-if="message.role === 'vector'">Response from vector DB</b>
      </h3>
      <articles-list
        v-if="message.role === 'vector'"
        :articles="message.articles"
      ></articles-list>
    </div>
  </div>
</template>
<script>
import ArticlesList from "./ArticlesList.vue";
import VueMarkdown from "vue-markdown-render";
export default {
  components: { ArticlesList, VueMarkdown },
  name: "DebugMeassage",
  data() {
    return {
      message: null,
    };
  },
  props: {
    m: {
      type: Object,
      required: true,
    },
  },
  watch: {
    m: {
      handler(val) {
        this.message = val;
      },
      immediate: true,
    },
  },
};
</script>
