<template>
  <div class="pt-5">
    <bread-crumb :items="bitems"></bread-crumb>
    <div class="flex flex-row justify-between">
      <h1 class="text-3xl text-gray-800 font-semibold mb-5">
        <svg
          class="w-9 h-9 text-gray-800 inline-block"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 6c0 1.657-3.134 3-7 3S5 7.657 5 6m14 0c0-1.657-3.134-3-7-3S5 4.343 5 6m14 0v6M5 6v6m0 0c0 1.657 3.134 3 7 3s7-1.343 7-3M5 12v6c0 1.657 3.134 3 7 3s7-1.343 7-3v-6"
          />
        </svg>
        Existing data sources
      </h1>
      <router-link
        v-if="sources.length"
        to="/sources/create"
        class="text-white bg-lime-500 h-8 hover:bg-lime-800 rounded-lg text-sm px-2 py-1 mr-5"
      >
        + Add new source
      </router-link>
    </div>
    <div class="mt-5">
      <div
        class="relative overflow-x-auto shadow-md sm:rounded-lg"
        v-if="sources.length"
      >
        <table
          class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
        >
          <thead
            class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
          >
            <tr>
              <th scope="col" class="px-6 py-3">Source name</th>
              <th scope="col" class="px-6 py-3">Type</th>
              <th scope="col" class="px-6 py-3">Status</th>
              <th scope="col" class="px-6 py-3">Documents</th>
              <th scope="col" class="px-6 py-3">Last updated</th>
              <th scope="col" class="px-6 py-3">
                <span class="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(source, si) in sources"
              v-bind:key="si"
              class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            >
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                {{ source.name }}
              </th>
              <td class="px-6 py-4">{{ source.source_type }}</td>
              <td class="px-6 py-4">
                <div class="flex items-center first-letter:uppercase">
                  <div class="h-2.5 w-2.5 rounded-full bg-green-500 me-2"></div>
                  {{ source.status }}
                </div>
              </td>
              <td class="px-6 py-4 font-bold">{{ source.documents_count }}</td>
              <td class="px-6 py-4">{{ source.updated_at }}</td>
              <td class="px-6 py-4 text-right">
                <a
                  href="#"
                  @click.prevent="deleteSource(source.id)"
                  class="font-medium text-red-600 hover:underline"
                  >Delete</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="!sources.length" class="w-full text-center p-5">
        <router-link
          to="/sources/create"
          class="text-white bg-lime-500 hover:bg-lime-800 rounded-lg text-md px-3 py-2 mt-5"
        >
          Create first data source
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../../api";
import BreadCrumb from "../BreadCrumb.vue";
export default {
  name: "SourcesList",
  data: function () {
    return {
      sources: [],
      bitems: [
        {
          text: "Sources list",
          link: "/sources",
        },
      ],
    };
  },
  components: {
    BreadCrumb,
  },
  beforeMount() {
    this.getSources();
  },
  methods: {
    getSources() {
      let self = this;
      api.get("/api/source/list").then((r) => {
        if (r.data) {
          self.sources = r.data.sources;
        }
      });
    },
    deleteSource(id) {
      let self = this;
      api.delete("/api/source/" + id).then((r) => {
        if (r.data) {
          self.getSources();
        }
      });
    },
  },
};
</script>
