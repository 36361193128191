<template>
  <div>
    <div class="chat-message flex p-4 text-gray-800 border-t mt-2">
      <svg
        class="w-6 h-6 text-gray-800 dark:text-white inline-block mr-3 basis-9"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M14 6.8a3 3 0 0 0-4.2.1M13 20h1a4 4 0 0 0 4-4V9A6 6 0 1 0 6 9v7m7 4v-1c0-.6-.4-1-1-1h-1a1 1 0 0 0-1 1v1c0 .6.4 1 1 1h1c.6 0 1-.4 1-1Zm-7-4v-6H5a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h1Zm12-6h1a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-1v-6Z"
        />
      </svg>
      <vue-markdown :source="m.content"></vue-markdown>
    </div>
    <div class="mt-2 flex justify-end">
      <span class="inline-block mr-2">Token spends:</span>
      <span
        class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300"
        >Prompt: {{ m.tokens.prompt_tokens }}</span
      >
      <span
        class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300"
        >Completion: {{ m.tokens.completion_tokens }}</span
      >
      <span
        class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300"
        >Total: {{ m.tokens.total_tokens }}</span
      >
    </div>
  </div>
</template>
<script>
import VueMarkdown from "vue-markdown-render";
export default {
  name: "AssistantReposnse",
  components: {
    VueMarkdown,
  },
  props: {
    m: {
      type: Object,
      required: true,
    },
  },
};
</script>
