<template>
  <div class="pt-5">
    <bread-crumb :items="bitems"></bread-crumb>
    <h1 class="text-3xl text-gray-800 font-semibold mb-5">
      <svg
        class="w-9 h-9 text-gray-800 inline-block"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m16 10 3-3m0 0-3-3m3 3H5v3m3 4-3 3m0 0 3 3m-3-3h14v-3"
        />
      </svg>
      <span v-if="!webhook.id">Create GPTs</span>
      <span v-else>Edit GPTs</span>
    </h1>
    <div class="shadow-md p-5 rounded-lg border-gray-100 border relative mb-8">
      <div class="mb-6">
        <input
          id="skip-title-checkbox"
          type="checkbox"
          v-model="webhook.is_active"
          class="w-4 h-4 text-blue-600 bg-white border-gray-300 rounded focus:ring-blue-500"
        />
        <label
          for="skip-title-checkbox"
          class="ms-2 text-md font-medium text-gray-900 dark:text-gray-300"
          >Active</label
        >
      </div>
      <div class="mb-6">
        <label
          for="assistant"
          class="block mb-2 text-md font-medium text-gray-900 dark:text-white"
          >Assistant</label
        >
        <select
          id="assistant"
          v-model="webhook.assistant_id"
          @change="assistantError = false"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          :class="assistantError ? 'border-2 border-rose-600' : ''"
        >
          <option
            v-for="(assistant, a) in assistants"
            v-bind:key="a"
            :value="assistant.id"
          >
            {{ assistant.name }}
          </option>
        </select>
      </div>
      <div class="mb-6">
        <button
          type="button"
          v-on:click="submitForm()"
          class="text-white bg-lime-500 hover:bg-lime-600 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
        >
          <span v-if="webhook.id">Update GPTs</span>
          <span v-else>Create GPTs</span>
        </button>
      </div>
    </div>
    <h1 class="text-2xl text-gray-800 font-semibold mb-5" v-if="webhook.id">
      <svg
        class="w-7 h-7 text-gray-800 inline-block"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          fill-rule="evenodd"
          d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm9.008-3.018a1.502 1.502 0 0 1 2.522 1.159v.024a1.44 1.44 0 0 1-1.493 1.418 1 1 0 0 0-1.037.999V14a1 1 0 1 0 2 0v-.539a3.44 3.44 0 0 0 2.529-3.256 3.502 3.502 0 0 0-7-.255 1 1 0 0 0 2 .076c.014-.398.187-.774.48-1.044Zm.982 7.026a1 1 0 1 0 0 2H12a1 1 0 1 0 0-2h-.01Z"
          clip-rule="evenodd"
        />
      </svg>
      GPTs quick start
    </h1>
    <div
      class="shadow-md p-5 rounded-lg border-gray-100 border relative"
      v-if="webhook.id"
    >
      <h2 class="text-xl font-medium text-gray-900 mb-6">Add GPTs actions</h2>
      <div class="mb-6 mt-3">
        <h2 class="text-md flex flex-row font-medium text-gray-900 mb-2">
          <span
            class="flex items-center justify-center w-8 h-8 border border-blue-600 rounded-full shrink-0"
          >
            1
          </span>
          <div class="ml-2 mt-1">Action schema</div>
        </h2>
        <div class="pl-10">
          <div class="text-md mb-3">
            Copy code bellow and paste at Schema window
          </div>
          <div
            class="relative bg-gray-900 py-1.5 px-4 rounded-lg border border-gray-200 not-italic"
          >
            <div
              class="space-y-2 text-yellow-300 text-xs leading-loose hidden sm:block"
            >
              <pre><code>{
  "openapi": "3.1.0",
  "info": {
    "title": "Get context for user query",
    "description": "Retrieves conext data for a user question.",
    "version": "v1.0.0"
  },
  "servers": [
    {
      "url": "https://ai.ordemio.com/"
    }
  ],
  "paths": {
    "/gpts/query/{{ webhook.assistant_id}}": {
      "post": {
        "description": "Get context for user query",
        "operationId": "GetContext",
        "requestBody": {
          "description": "Data for completion request",
          "required": true,
          "content": {
            "application/json": {
              "schema": {
                "type": "object",
                "properties": {
                  "query_text": {
                    "type": "string",
                    "description": "User input message for processing"
                  }
                },
                "required": ["query_text"]
              }
            }
          }
        },
        "deprecated": false,
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "documents": {
                      "description": "The context documents",
                      "type": "array",
                      "items": {
                        "type": "object",
                        "properties": {
                          "title": {
                            "type": "string",
                            "description": "Title document"
                          },
                          "content": {
                            "type": "integer",
                            "description": "Content context document"
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  "components": {
    "schemas": {}
  }
}
</code></pre>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-8">
        <h2 class="text-md flex flex-row font-medium text-gray-900 mb-2">
          <span
            class="flex items-center justify-center w-8 h-8 border border-blue-600 rounded-full shrink-0"
          >
            2
          </span>
          <div class="ml-2 mt-1">Set Authentication</div>
        </h2>
        <div class="text-md mb-3 pl-10">
          Select Authentication Type as API Key and choise Auth Type Bearer
          <img
            src="/images/gpts_help_1.png"
            class="w-[350px] shadow-lg rounded-lg mt-3 block mb-6"
          />
          <div>
            Your API key:
            <span class="p-3 bg-gray-900 text-yellow-300 rounded-md text-sm">
              {{ user.api_key }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../../api";
import BreadCrumb from "../BreadCrumb.vue";
export default {
  name: "WebhookCreate",
  data: function () {
    return {
      id: this.$route.params.id,
      webhook: {
        assistant_id: null,
        outgoing_url: null,
        is_active: true,
      },
      assistantError: false,
      urlError: false,
      assistants: [],
      bitems: [
        {
          text: "Webhook integration",
          link: "/integrations/webhook",
        },
        {
          text: "Webhook",
          link: "#",
        },
      ],
      user: { api_key: null },
    };
  },
  components: {
    BreadCrumb,
  },
  beforeMount() {
    this.getAssistants();
    this.getUser();
    if (this.id && this.id.length) {
      this.getWebhook();
    }
  },
  methods: {
    getUser() {
      let that = this;
      api.get("/api/account/get").then((r) => {
        that.isLoading = false;
        if (r.data) {
          that.user = r.data.user;
        }
      });
    },
    getAssistants() {
      let self = this;
      api.get("/api/assistant/list").then((r) => {
        if (r.data) {
          self.assistants = r.data.assistants;
        }
      });
    },
    getWebhook() {
      let self = this;
      api.get("/api/integrations/webhook/" + this.id).then((r) => {
        if (r.data) {
          self.webhook = r.data.webhook;
        }
      });
    },
    submitForm() {
      let self = this;
      this.urlError = false;
      this.assistantError = false;
      if (!this.webhook.assistant_id) {
        this.assistantError = true;
        return;
      }
      if (this.webhook.id) {
        api
          .put("/api/integrations/webhook/" + this.webhook.id, this.webhook)
          .then((r) => {
            if (r.data) {
              self.$router.push("/integrations/webhook/");
            }
          });
      } else {
        api.post("/api/integrations/webhook/", this.webhook).then((r) => {
          if (r.data) {
            self.webhook.id = r.data.webhook;
          }
        });
      }
    },
  },
};
</script>
