<template>
  <div>
    <h1 class="text-4xl text-gray-800 font-semibold mb-5">API Keys</h1>
    <div class="relative overflow-x-auto sm:rounded-lg">
      <table
        v-if="keys.length"
        class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
      >
        <thead
          class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
        >
          <tr>
            <th scope="col" class="px-6 py-3">Key name</th>
            <th scope="col" class="px-6 py-3">Secret key</th>
            <th scope="col" class="px-6 py-3">Enabled</th>
            <th scope="col" class="px-6 py-3">Created at</th>
            <th scope="col" class="px-6 py-3">
              <span class="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="key in keys"
            v-bind:key="key.id"
            class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
          >
            <th
              scope="row"
              class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
            >
              <span v-if="key.edit">
                <input
                  key="name"
                  v-model="key.name"
                  type="text"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
                />
              </span>
              <span v-else>
                {{ key.name }}
              </span>
            </th>
            <td class="px-6 py-4">
              <span v-if="key.edit">
                <input
                  key="key"
                  v-model="key.key"
                  type="text"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg"
                />
              </span>
              <span v-else>
                {{ key.key }}
              </span>
            </td>
            <td class="px-6 py-4">
              <span v-if="key.edit">
                <input
                  key="key"
                  v-model="key.is_active"
                  type="checkbox"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm"
                />
              </span>
              <span v-else>
                <svg
                  v-if="key.is_active"
                  class="w-3 h-3 text-green-400 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 16 12"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 5.917 5.724 10.5 15 1.5"
                  />
                </svg>
                <svg
                  v-else
                  class="w-3 h-3 text-red-400 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
              </span>
            </td>
            <td class="px-6 py-4">
              <span v-if="!key.edit">{{ key.created_at }}</span>
            </td>
            <td class="px-6 py-4 text-right">
              <a
                v-if="!key.edit"
                href="#"
                @click="key.edit = true"
                class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                >Edit</a
              >
              <a
                v-else
                @click="saveKey(key)"
                href="#"
                class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                >Add</a
              >
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        <div class="mx-auto p-5 w-full text-center">
          <div class="bg-gray-100 p-3 rounded-lg w-[55px] mx-auto mb-5">
            <svg
              class="w-8 h-8 text-gray-600 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M18.5 8V4.5a3.5 3.5 0 1 0-7 0V8M8 12.167v3M2 8h12a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1Z"
              />
            </svg>
          </div>
          <p class="text-xl">No API keys found.</p>
          <p class="mb-3">
            See your
            <a
              href="https://platform.openai.com/api-keys"
              class="underline hover:bg-gray-200"
            >
              available API keys on OpenAI
              <svg
                class="w-3 h-3 text-gray-800 hover:text-white inline-block -mt-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 18"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"
                />
              </svg>
            </a>
            and create your first key.
          </p>
          <button
            type="button"
            @click="addKeyModal"
            class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 hover:bg-gray-600"
          >
            Create key
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "flowbite/dist/flowbite.js";
import api from "../../api";
export default {
  name: "KeysList",
  data: function () {
    return {
      keys: [],
      modal: null,
    };
  },
  beforeMount() {
    this.getKeys();
  },
  methods: {
    getKeys() {
      let that = this;
      api.get("/api/keys/list").then((r) => {
        if (r.data) {
          that.keys = r.data;
        }
      });
    },
    saveKey(key) {
      let that = this;
      if (key.id) {
        api.put("/api/keys/key/" + key.id, key).then(() => {
          that.getKeys();
        });
        return;
      }
      api.post("/api/keys/key", key).then(() => {
        that.getKeys();
      });
    },
    addKeyModal() {
      this.keys.push({
        id: null,
        name: "",
        key: "",
        is_active: true,
        edit: true,
      });
    },
  },
};
</script>
