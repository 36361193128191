<template>
  <div class="mt-2">
    <span v-for="(article, k) in list" :key="k">
      <h2>
        <button
          type="button"
          @click="toggleVisible(article)"
          class="flex items-center justify-between w-full p-3 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200"
          :class="k === 0 ? '' : 'border-t-0'"
          aria-expanded="true"
        >
          <div>
            Article # {{ k }}
            <span
              class="bg-gray-200 text-gray-800 font-medium me-2 px-2.5 py-0.5 rounded inline-block ml-2"
              >score: {{ article.score }}</span
            >
          </div>
          <svg
            data-accordion-icon
            class="w-3 h-3 rotate-180 shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div :class="article.show ? '' : 'hidden'">
        <div
          class="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900"
        >
          {{ article.content }}
        </div>
      </div>
    </span>
  </div>
</template>
<script>
export default {
  name: "ArticlesList",
  props: {
    articles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      list: [],
    };
  },
  watch: {
    articles: {
      handler(val) {
        this.list = val;
      },
      immediate: true,
    },
  },
  methods: {
    toggleVisible(article) {
      article.show = !article.show;
    },
  },
};
</script>
