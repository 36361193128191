<template>
  <div class="pt-5">
    <bread-crumb :items="bitems"></bread-crumb>
    <div class="flex flex-row justify-between">
      <h1 class="text-3xl text-gray-800 font-semibold mb-5">
        <svg
          class="w-9 h-9 text-gray-800 inline-block"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M14.079 6.839a3 3 0 0 0-4.255.1M13 20h1.083A3.916 3.916 0 0 0 18 16.083V9A6 6 0 1 0 6 9v7m7 4v-1a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1Zm-7-4v-6H5a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h1Zm12-6h1a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-1v-6Z"
          />
        </svg>
        Existing assistants
      </h1>
      <router-link
        v-if="assistants.length"
        to="/assistant/create"
        class="text-white bg-lime-500 h-8 hover:bg-lime-800 rounded-lg text-sm px-2 py-1 mr-5"
      >
        + Add new assistant
      </router-link>
    </div>
    <div class="mt-5">
      <div
        class="relative overflow-x-auto shadow-md sm:rounded-lg"
        v-if="assistants.length"
      >
        <table
          class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
        >
          <thead
            class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
          >
            <tr>
              <th scope="col" class="px-6 py-3">Assistant name</th>
              <th scope="col" class="px-6 py-3">Active</th>
              <th scope="col" class="px-6 py-3">Indexes</th>
              <th scope="col" class="px-6 py-3">
                Title / Vector ratio / Docuemnts
              </th>
              <th scope="col" class="px-6 py-3">Last updated</th>
              <th scope="col" class="px-6 py-3">
                <span class="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(assistant, si) in assistants"
              v-bind:key="si"
              class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            >
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                <router-link
                  :to="'/assistant/' + assistant.id"
                  class="font-medium hover:underline"
                  >{{ assistant.name }}</router-link
                >
              </th>
              <td class="px-6 py-4">
                <div class="flex items-center first-letter:uppercase">
                  <div
                    class="h-2.5 w-2.5 rounded-full me-2"
                    :class="assistant.is_active ? 'bg-green-500' : 'bg-red-500'"
                  ></div>
                  <span v-if="assistant.is_active">Active</span>
                  <span v-else>Disabled</span>
                </div>
              </td>
              <td class="px-6 py-4 font-bold">
                <span
                  v-for="(index, si) in assistant.indexes"
                  v-bind:key="si"
                  class="mr-1 py-0.5 px-1 bg-blue-200 rounded-md"
                >
                  {{ getIndexName(index.index_id) }}
                </span>
              </td>
              <td class="px-6 py-4">
                {{ assistant.title_ratio }} / {{ assistant.vector_ratio }} /
                {{ assistant.number_docs }}
              </td>
              <td class="px-6 py-4">
                {{ assistant.updated_at }}
              </td>
              <td class="px-6 py-4 text-right gap-4">
                <a
                  href="#"
                  @click="deleteAssistant(assistant.id)"
                  class="font-medium text-red-600 hover:underline inline-block ml-2"
                  >Delete</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="!assistants.length" class="w-full text-center p-5">
        <router-link
          to="/assistant/create"
          class="text-white bg-lime-500 hover:bg-lime-800 rounded-lg text-md px-3 py-2 mt-5"
        >
          Create first assistant
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../../api";
import BreadCrumb from "../BreadCrumb.vue";
import _ from "lodash";
export default {
  name: "AssistantsList",
  data: function () {
    return {
      assistants: [],
      bitems: [
        {
          text: "Assistants list",
          link: "/assistant",
        },
      ],
      indexes: [],
    };
  },
  components: {
    BreadCrumb,
  },
  beforeMount() {
    this.getAssistants();
    this.getIndexes();
  },
  methods: {
    getAssistants() {
      let self = this;
      api.get("/api/assistant/list").then((r) => {
        if (r.data) {
          self.assistants = r.data.assistants;
        }
      });
    },
    getIndexes() {
      let self = this;
      api.get("/api/index/list").then((r) => {
        if (r.data) {
          self.indexes = r.data.indexes;
        }
      });
    },
    deleteAssistant(id) {
      let self = this;
      api.delete("/api/assistant/" + id).then((r) => {
        if (r.data) {
          self.getAssistants();
        }
      });
    },
    getIndexName(index) {
      let index_ob = _.find(this.indexes, { id: index });
      if (index_ob) {
        return index_ob.name;
      }
    },
  },
};
</script>
